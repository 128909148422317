import React, { useState } from "react";
import { PageTitle } from "./../../pageTitle/PageTitle";
import { IconContext } from "react-icons";
import "./contacto.css";
import {
  RiPhoneLine,
  RiMailLine,
  RiMapPinLine,
  RiWhatsappLine,
} from "react-icons/ri";

// probably a total mess u.u

const Contacto = () => {
  return (
    <div>
      <PageTitle title="Contacto"></PageTitle>
      <div className="contact-background">
        <div className="contact-div">
          <ContactForm></ContactForm>
          <ContactInfo></ContactInfo>
        </div>
      </div>
    </div>
  );
};
export default Contacto;

const ContactForm = () => {
   const [status, setStatus] = useState("Submit");
   
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Enviando...");
    const { name, email, message, phone } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      phone: phone.value,
      message: message.value,
        };
        let response = await fetch("https://ludmilarosas.com.ar:5000/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    alert(result.status);
  };
  const handleInvalid = (invalid) => {
    invalid.preventDefault();
    invalid.target.classList.add("invalid");
  };

  
  const resetInvalid = (event) => {
    if (event.target.value !== "") {
      event.target.classList.remove("invalid");
    }
  };
  return (
    <div className="contact-form-div">
    <form className="contact-form-div" onSubmit={handleSubmit}>
      <div>
        <label className="form-subtitles " htmlFor="name">Nombre:</label>
        <input  className="contact-field contact-field-input" type="text" id="name" required />
      </div>
      <div>
        <label className="form-subtitles" htmlFor="email">Email:</label>
        <input  className="contact-field contact-field-input" type="email" id="email" required />
      </div>
      <div>
        <label  className="form-subtitles" htmlFor="phone">Teléfono:</label></div>
        <input className="contact-field contact-field-input" type="phone" id="phone" required />
        <div>
        <label  className="form-subtitles" htmlFor="message">Mensaje:</label>
        <textarea     className="contact-field contact-field-textarea" id="message" rows="10" required />
      </div>
      <button className="contact-form-submit" type="submit" value="Enviar">{status}</button>
    </form>
    </div>
  );
  
    
};
const ContactInfo = () => {
  return (
    <div className="contact-info-div">
      <IconContext.Provider value={{ className: "contact-icons" }}>
        <div className="contact-info-container">
          <div className="contact-info-subtitles">
            <RiPhoneLine></RiPhoneLine>
            <h4 className="contact-info-title">Teléfono fijo </h4>
          </div>
          <p className="contact-info-text">2257-460622</p>
        </div>
        
        <div className="contact-info-container">
          <div className="contact-info-subtitles">
            <RiWhatsappLine></RiWhatsappLine>
           <a href="https://wa.me/2257553534"> <h4 className="contact-info-title">Whatsapp</h4></a>
          </div>
         
         
          <p className="contact-info-text">2257-553534</p>
        </div>
        <div className="contact-info-container">
          <div className="contact-info-subtitles">
            <RiMailLine></RiMailLine>
            <h4 className="contact-info-title">Correo Electrónico</h4>
          </div>
          <p className="contact-info-text">info@ludmilarosas.com.ar</p>
        </div>
        <div className="contact-info-container">
          <div className="contact-info-subtitles">
            <RiMapPinLine></RiMapPinLine>
            <h4 className="contact-info-title">Dirección</h4>
          </div>
          <p className="contact-info-text">
            San Bernardo - Partido de La Costa, Provincia de Buenos Aires, Argentina
          </p>
        </div>
      </IconContext.Provider>
    </div>
  );
};
