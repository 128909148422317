import firebase from "firebase/app";
import 'firebase/database';
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

firebase.initializeApp({
 apiKey: "AIzaSyBUxRep1PUU3mzHOKGTym3KPqYiDej69UM",
  authDomain: "multitiendatryteck.firebaseapp.com",
  databaseURL: "https://multitiendatryteck-default-rtdb.firebaseio.com",
  projectId: "multitiendatryteck",
  storageBucket: "multitiendatryteck.appspot.com",
  messagingSenderId: "285165539887",
  appId: "1:285165539887:web:9873aa520389fa4aca2cda",
  measurementId: "G-HNQ83HRLJ0"
});


export const firestore = firebase.firestore();
export const database = firebase.database();
export const storage = firebase.storage();

export const auth = firebase.auth();
